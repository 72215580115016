<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <v-card class="_100top pa-3 radius-card" min-height="500px">
      <h3>Quiz</h3>
      <!-- <v-divider></v-divider> -->
      <div class="mt-3" v-if="list">
        <v-row>
          <v-col cols="3" v-for="(item, idx) in list.data" :key="idx">
            <v-card class="radius-card" style="overflow: hidden">
              <v-img
                :src="require('@/assets/img/banner-quiz.jpeg')"
                height="100px"
                style="object-fit: cover"
              ></v-img>
              <div class="pa-3">
                <h2 class="text-center">{{ item.nama }}</h2>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="purple"
                  block
                  @click="toTest(item)"
                  ><b>Mulai tes</b></v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "getQuiz",
  computed: {
    ...mapState({
      list: state => state.quiz.listQuiz
    })
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("quiz/getList").then(data => {
        console.log(data);
      });
    },
    toTest(item) {
      console.log(item.id);
      this.$router.push(`/quiz/gad/${item.id}`);
    }
  }
};
</script>

<style></style>
